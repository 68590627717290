import React from "react";

const HeroPatients = ({ children, className, heroImg, heroImgAlt, ...props }) => {
	return (
		<div {...props} className={`hero-content hero-content-pat ${className ? className : ""}`}>
			<div className="caption-wrapper">{children}</div>
		</div>
	);
};

export default HeroPatients;
